import querystring from 'querystring';
import type { GetServerSideProps, NextPage } from 'next';
import { z } from 'zod';
import { ssrThemeProps } from '@stitch-fix/sf-next/server';
import Script from 'next/script';
import { modularPageWrapperSchema } from '../../components/ModularPageWrapper/schema';
import { getCachedEntryByUrl } from '../../utils/requests/contentstack/cachedRequests';
import { formatError, serverLogger } from '../../utils/loggers/server';
import { ModularPageWrapper } from '../../components/ModularPageWrapper';
import {
  getReactivationUrl,
  isDynamicAdsLandingPage,
} from '../../utils/redirects';
import {
  isAllSettledResponseFailure,
  logResponsesFromAllSettledSSR,
} from '../../utils/promises';
import { getDynamicAdsRequest } from '../../features/dynamicAds/utils/getDynamicAdsRequest';
import { getModulesWithHeroOverride } from '../../features/dynamicAds/utils/getModulesWithHeroOverride';

export type WomenPageProps = z.infer<typeof modularPageWrapperSchema>;

export const getServerSideProps: GetServerSideProps<WomenPageProps> = async ({
  locale,
  req,
  query,
}) => {
  const url = req.url || '';
  const stringifiedQuery = querystring.stringify(query);

  const reactivationUrl = getReactivationUrl(url);

  if (reactivationUrl) {
    return {
      redirect: {
        destination: `${reactivationUrl}?${stringifiedQuery}`,
        permanent: false,
      },
    };
  }

  try {
    const entryRequest = await getCachedEntryByUrl({
      contentType: 'modular_page',
      url: '/womens-onboarding-vision',
      language: locale,
      schema: modularPageWrapperSchema,
      logger: serverLogger,
    });

    let dynamicAdsRequest: ReturnType<typeof getDynamicAdsRequest> | null =
      null;

    // only call the dynamic ads algos if the page is a dynamic ads landing page
    if (isDynamicAdsLandingPage(url)) {
      dynamicAdsRequest = getDynamicAdsRequest({ req });
    }

    /**
     * this allows us to handle a dynamic number of requests in a non-synchronous way
     */
    const responses = await Promise.allSettled([
      entryRequest,
      dynamicAdsRequest,
    ]);

    logResponsesFromAllSettledSSR({
      responses,
      message: 'women data error',
      team: 'client-onboarding',
    });

    const [entryResponse, dynamicAdsResponse] = responses;

    if (isAllSettledResponseFailure(entryResponse)) {
      throw entryResponse.reason;
    }

    const dynamicAdsData =
      dynamicAdsResponse.status === 'fulfilled'
        ? dynamicAdsResponse.value?.data
        : undefined;

    return {
      props: {
        seoData: entryResponse.value.data.seoData,
        modules: getModulesWithHeroOverride({
          businessLinePage: 'women',
          modules: entryResponse.value.data.modules,
          dynamicAds: dynamicAdsData,
        }),
        ...(await ssrThemeProps({
          req,
        })),
      },
    };
  } catch (error: unknown) {
    serverLogger({
      level: 'error',
      team: 'client-onboarding',
      message: 'Required page data is undefined - Women',
      context: {
        error: formatError(error),
        page: '/women',
        severity: 'critical',
      },
    });

    throw new Error('Required data not provided to render Women page');
  }
};

const WomenPage: NextPage<WomenPageProps> = ({ seoData, modules }) => {
  return (
    <>
      <Script
        // Blueshift web conversion integration (https://experiences.getblueshift.com/)
        data-testid="blueshift-script"
        src="https://www.lightboxcdn.com/vendor/3767d51a-4af9-4270-8ee4-c7310e4371b0/lightbox_speed.js"
        defer
      />
      <ModularPageWrapper seoData={seoData} modules={modules} />;
    </>
  );
};

export default WomenPage;
